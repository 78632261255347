import React, { useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import axios from "axios";
import { endPoint } from "../../../utils/api";

const AuthBanner = () => {
  const [userDetail, setUserDetail] = useState(null);
  const navigate = useNavigate();

  const navigateHandler = (e) => {
    if (e === "login") {
      navigate("/login");
    } else if (e === "sign-up") {
      navigate("/sign-up");
    } else if (e === "home") {
      navigate("/");
    }
  };

  useEffect(() => {
    const getUserDetail = async () => {
      const u = localStorage.getItem("user");
      const userObjlocal = JSON.parse(u)

      if (userObjlocal?.user?.verified) {
        
        try {
          // const { data } = await axios.get(`${endPoint}/api/giveaway/user`, {
          //   email: userObjlocal.email,
          // });
          setUserDetail(userObjlocal);
        } catch (error) {
          console.log("Error while geting user data", error);
        }
      }
    };

    getUserDetail();
  }, []);

  return (
    <>
      <div className="header-main">
        <div className="container">
          <div className="row pt-3">
            <div className="col ">
              <div
                className="brand-name"
                onClick={() => navigateHandler("home")}
              >
                <b>
                  <i >Giveaway Shop</i>
                </b>
              </div>
            </div>
            <div className="col">
              {userDetail ? (
                <p className="username-text" style={{marginLeft: "auto"}}>
                  <i>Hi, {userDetail?.user?.userName}</i>
                </p>
              ) : (
                <div className="links-in-header">
                  <p
                    className="link-of-header"
                    onClick={() => navigateHandler("login")}
                    style={{ marginLeft: "auto", marginRight: "20px" }}
                  >
                    Login
                  </p>
                  <p
                    className="link-of-header"
                    onClick={() => navigateHandler("sign-up")}
                  >
                    Sign Up
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthBanner;
