import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CelebrationIcon from '@mui/icons-material/Celebration';
import Confetti from "react-confetti";

const CongratulationsDialog = ({open, setOpen, confettiFlag}) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    setOpen(true); // Open dialog on successful form submission
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <form onSubmit={handleSubmit}>
        <Button type="submit" variant="contained" color="primary">
          Submit Form
        </Button>
      </form> */}
      {/* {confettiFlag && <Confetti className="confetti-component" />} */}

      <Dialog className='congrate-dialog-main' open={open} onClose={handleClose}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <CelebrationIcon fontSize="large" className='celebrate-icon' style={{color:"#f68341"}} sx={{ mr: 1 }} />
            Congratulations!
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Typography variant="h6" gutterBottom className='dialog-success-heading'>
            Your form is successfully submitted.
          </Typography>
          <Typography variant="body1">
            Thank you for completing the process. We appreciate your input and will get back to you soon.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} className='dialog-btn' variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CongratulationsDialog;
