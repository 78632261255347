import React from "react";

const SmallHeader = () => {
  return (
    <header className="rydeon-header">
      <div className="container small-nav-header">
        <div className="row align-items-center justify-content-between">
          {/* Logo Section */}
          <div className="col-auto">
            <a className="navbar-brand d-flex align-items-center" href="/">
              <img
                className="nav-brand-image"
                src="./assets/logo.png"
                alt="Brand logo"
              />
              <span className="brand-nav-text">Rydeon</span>
            </a>
          </div>

          {/* Navigation Links */}
          <nav className="col-auto  d-flex">
            <a className="nav-link" href="#rydeon-footer">Download</a>
            <a className="nav-link nav-other" href="#whyRydeonDiv">Why Rydeon?</a>
            <a className="nav-link nav-other" href="#howItWorksSection">How It Works?</a>
          </nav>

          {/* Mobile Hamburger Icon */}
          {/* <div className="col-auto d-md-none">
            <button className="navbar-toggler" type="button">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div> */}
        </div>
      </div>
    </header>
  );
};

export default SmallHeader;
