import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { useState } from "react";
import { endPoint } from "../../../../utils/api";
import axios from "axios";

function SignUpForm({ loading, setLoading, setOtpFlag }) {
  const navigate = useNavigate();
  const [signUserName, setSignUserName] = useState('');
  const [signEmail, setSignEmail] = useState('');
  const [signErrorMsg, setSignErrorMsg] = useState('');

  const toSignUpClickHandler = () => {
    navigate("/login");
  };

  const signSubmitHandler = async (e) => {
    e.preventDefault();
    setSignErrorMsg(''); // Clear previous errors
    localStorage.setItem("email", signEmail)

    if (!signUserName || signUserName.length < 3) {
      return setSignErrorMsg('Username must be at least 3 characters long.');
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(signEmail)) {
      return setSignErrorMsg('Please enter a valid email address.');
    }

    const userObj = {
      userName: signUserName,
      email: signEmail
    };

    try {
      setLoading(true);
      const { data } = await axios.post(`${endPoint}/api/giveaway/user`, userObj);
      setLoading(false);
      setOtpFlag(true);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data) {
        // Server-side error
        setSignErrorMsg(`${error.response.data.message}`);
      } else {
        // Client-side or network error
        setSignErrorMsg('An error occurred while signing up. Please try again later.');
      }
    }
  };

  return (
    <Form onSubmit={signSubmitHandler}>
      <h1 className="form-heading">Verify Your Email</h1>
      
      <Form.Group className="mb-3" controlId="formBasicUserName">
        <Form.Label>User Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="User Name"
          value={signUserName}
          onChange={(e) => setSignUserName(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={signEmail}
          onChange={(e) => setSignEmail(e.target.value)}
          required
        />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>
      
      {loading && <p> Please Wait... </p>}
      {signErrorMsg && <p style={{ color: "red" }}>{signErrorMsg}</p>}
      
      <Button className='login-submit-btn' variant="primary" type="submit" disabled={loading}>
        Verify email &nbsp;&nbsp;
        {loading && <AutorenewOutlinedIcon className="rotate" fontSize="small" />}
      </Button>

      <div className="not-have-account">
        <p className='not-have-acc-txt'>Already registered?</p>
        <p className="to-sign-btn" onClick={toSignUpClickHandler}>Login</p>
      </div>
    </Form>
  );
}

export default SignUpForm;
