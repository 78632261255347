import React from 'react'
import AuthBanner from '../AuthBanner'
import BasicExample from './Form'

const Login = () => {
  return (
    <>
        <AuthBanner />
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col login-form-main">
                    <BasicExample />
                </div>
            </div>
        </div>
    </>
  )
}

export default Login