import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { useState } from "react";

function BasicExample() {
  const [loginSubmitFlag, setLoginSubmitFlag] = useState(false);
  const [loginLoadnigFlag, setLoadnigSubmitFlag] = useState(false);

  const navigate = useNavigate();

  const toSignUpClickHandler = ()=>{
    navigate("/sign-up")
  }
  const loginSubmitHandler = (e)=>{
    e.preventDefault();

    setLoadnigSubmitFlag(true);
    setTimeout(() => {
      setLoadnigSubmitFlag(false)
      setLoginSubmitFlag(true)
    }, 2000);
  }
  return (
    <Form onSubmit={loginSubmitHandler}>
        <h1 className="form-heading">Login</h1>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>User Name</Form.Label>
        <Form.Control type="text" placeholder="User Name" required/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" required />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>
      {loginSubmitFlag && <p style={{ color: "red" }}> User Not Found please register first. </p>}
      <Button className='login-submit-btn' variant="primary" type="submit">
        Login &nbsp;&nbsp;
        {loginLoadnigFlag && <AutorenewOutlinedIcon className="rotate" fontSize="small"/>}
      </Button>

      <div className="not-have-account">
        <p className='not-have-acc-txt'>Don't have an account?</p>
        <p className="to-sign-btn" onClick={toSignUpClickHandler}> Sign Up Now</p>
      </div>
    </Form>
  );
}

export default BasicExample;