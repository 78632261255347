import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField as Input,
} from "@mui/material";

const CardTopPart = () => {
  return (
    <>
      <Typography variant="h5" className="fw-500 text-center" sx={{ pb: 3 }}>
        Zero Cost: Verify only!
      </Typography>
      <Box sx={{ pb: 3 }} className="flex justify-content-between">
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="credit"
            name="radio-buttons-group"
            sx={{
              ".Mui-checked": {
                color: "var(--main-color) !important",
              },
              span: {
                fontWeight: "500",
              },
            }}
          >
            <FormControlLabel
              value="credit"
              control={<Radio />}
              label="Credit or Debit Card"
            />
          </RadioGroup>
        </FormControl>
        <Box>
          <img src="/assets/svg/visa.svg" alt="visa" width={40} height={40} />
          <img
            src="/assets/svg/master_card.svg"
            alt="visa"
            width={40}
            height={40}
          />
        </Box>
      </Box>
    </>
  );
};

export default CardTopPart;
