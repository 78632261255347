import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SmallHeader from "./app/components/SmallHeader";
import Footer from "./app/components/Footer";
import HomePage from "./app/pages/home/HomePage";
import Page404 from "./app/pages/Page404";
import PageTitle from "./app/components/PageTitle";
import Login from "./app/pages/auth/login/Login";
import SignUp from "./app/pages/auth/signup/SignUp";
import Card from "./app/pages/card/Card";
import ExcelReader from "./app/pages/other/Excel";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./app/main.css"
import "./App.css";
import "./AppMediaQuery.css";

function App() {
  return (
    <>
      <Router>
        {/* <SmallHeader /> */}

        <Routes>
          <Route
            path="/"
            element={
              <>
                <PageTitle title="Giveawayshop - Home Page" />
                <HomePage />
              </>
            }
          />
          {/* <Route
            path="/excel"
            element={
              <>
                <ExcelReader />
              </>
            }
          /> */}
          <Route
            path="/login"
            element={
              <>
                <PageTitle title="Login to Giveawayshop" />
                <Login />
              </>
            }
          />
          <Route
            path="/Sign-up"
            element={
              <>
                <PageTitle title="Verify to Giveawayshop" />
                <SignUp />
              </>
            }
          />
          <Route
            path="/card-to-join"
            element={
              <>
                <PageTitle title="Card to Giveawayshop" />
                <Card />
              </>
            }
          />
          <Route
            path="/*"
            element={
              <>
                <PageTitle title="404 - Page not found" />
                <Page404 />
              </>
            }
          />
        </Routes>

        <Footer />
      </Router>
    </>
  );
}

export default App;
