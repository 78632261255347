import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endPoint } from "../../../utils/api";

const Banner = () => {
  const [userDetail, setUserDetail] = useState(null);
  const navigate = useNavigate();

  const loginClickHandler = ()=>{
    const u = localStorage.getItem("user");
    const userObjlocal = JSON.parse(u)

    if (userObjlocal?.user?.verified) {
      navigate("/card-to-join")
    }else{
      navigate("/sign-up")
    }
    
  }

  useEffect(()=>{
    const getUserDetail = async()=>{
      const u = localStorage.getItem("user");
      const userObjlocal = JSON.parse(u)
      if (userObjlocal?.user?.verified) {
        try {
          // const { data } = await axios.post(`${endPoint}/api/giveaway/user`,{email:email} );
          setUserDetail(userObjlocal)
        } catch (error) {
          console.log("Error while geting user data", error);
        }
      }

    }

    getUserDetail()
  },[])

  return (
    <>
      <div className="banner-hero">
        <div class="container">
          <div className="login-sign-btn-div">
            {userDetail? <p className="username-text"><i>Hi, {userDetail?.user?.userName}</i></p> : <button className="login-sign-btn" onClick={loginClickHandler}>Login / Sign up</button>}
          </div>
          <div class="row">
            <div class="col-md-6" id="banner-text">
              <h3 class="title" id="banner-heading">
              Welcome to Giveawayshop
              </h3>
              <p class="lead" id="banner-para">
              Join the exclusive giveaway and win the all-new iPhone 16! With only limited time left, make sure to secure your spot before the timer runs out. Sign up now and add your card for 0$ – no charges, just a quick verification for trust. This is your chance to be a part of something big, so don't miss out! Time is ticking – act fast!
              </p>
            </div>
              <div style={{marginBottom: "30px"}}>
              <a onClick={loginClickHandler} className="cta-button">I Want to win!</a>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
