import React from "react";

const CardTop = () => {
  return (
    <div className="row card-first-section">
      <div className="col">
        <div className="">
          <div className="product-desc">
            <b className="me-4">Product:</b>
            <p>Iphone 16 Pro</p>
          </div>
          <hr style={{ margin: "5px" }} />
          <div className="product-desc">
            <b className="me-4">Quantity:</b>
            <p>1</p>
          </div>
          <hr style={{ margin: "5px" }} />
          <div className="product-desc">
            <b className="me-4">Amount:</b>
            <p>$0</p>
          </div>
          <hr style={{ margin: "5px" }} />
        </div>
      </div>
      <div className="col">
        <img
          className="card-iphone-image"
          src="assets/download/credit-card.png"
          alt="credit card image"
        />
      </div>
    </div>
  );
};

export default CardTop;
