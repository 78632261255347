import React, { useEffect, useState } from "react";
import axios from "axios";
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { endPoint } from "../../../utils/api";

const Timer = () => {
  const [timerData, setTimerData] = useState({});
  const [count, setCount] = useState(0);
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();

  useEffect(()=>{
    const getTimer = async()=>{
      try {
        const {data} = await axios.get(`${endPoint}/api/giveaway/timer`);
        console.log(data);
        setTimerData(data);
      } catch (error) {
        console.log("Error while getting timer data: ", error.message);
      }
    }
    getTimer()
  },[]);

  useEffect(() => {
    const interval = setInterval(() => {
        setCount(count + 1);
        const now = new Date().getTime(); 
        if (Object.keys(timerData).length !== 0) {
          const elapsedTime = now - timerData?.timer?.startTime; 
          const remainingTime = timerData?.timer?.time - elapsedTime; 
  
          const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
          const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
  
          setDays(days.toString());
          setHours(hours.toString())
          setMinutes(minutes.toString())
          setSeconds(seconds.toString())
        }
    }, 1000);

    return () => clearInterval(interval);
}, [count]);

  return (
    <>
      <div className="intro-section">
        <div className="container">
          <p className="how-works-h text-center giveaway-remaing-time">Giveaway Remaining Time!</p>
          <div className="timer-main">
            <div className="col timer-small-box">
              <p className="timer-numbers"> {days  ? days : <AutorenewOutlinedIcon className="rotate" fontSize="large"/>} </p>
              <p className="timer-text">Days</p>
            </div>
            <div className="col timer-small-box">
              <p className="timer-numbers"> {hours ? hours : <AutorenewOutlinedIcon className="rotate" fontSize="large"/>} </p>
              <p className="timer-text">Hours</p>
            </div>
            <div className="col timer-small-box">
              <p className="timer-numbers"> {minutes ? minutes : <AutorenewOutlinedIcon className="rotate" fontSize="large"/> } </p>
              <p className="timer-text">Minutes</p>
            </div>
            <div className="col timer-small-box">
              <p className="timer-numbers"> {seconds ? seconds : <AutorenewOutlinedIcon className="rotate" fontSize="large"/>} </p>
              <p className="timer-text">Seconds</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Timer;
