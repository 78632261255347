import React, { useState } from "react";
import SignUpForm from "./signUpForm";
import AuthBanner from "../AuthBanner";
import VerifyEmailForm from "./verifyEmailForm";

const SignUp = () => {
  const [otpFlag, setOtpFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <>
      <AuthBanner />
      <div className="container login-form-container mt-5 mb-5">
        <div className="row">
          <div className="col login-form-main">
            {!otpFlag && (
              <SignUpForm setLoading={setLoading} loading={loading} setOtpFlag={setOtpFlag} />
            )}

            {otpFlag && <VerifyEmailForm setOtpFlag={setOtpFlag} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
