import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { endPoint } from "../../../../utils/api";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";

const VerifyEmailForm = ({setOtpFlag}) => {
  const [otpValue, setValue] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [signErrorMsg, setSignErrorMsg] = useState('');
  // const [otpValue, setOtpValue] = useState('');
  const navigate = useNavigate();

  const toCardClickHandler = () => {
    navigate("/card-to-join");
  };
  const returntoSignForm = ()=>{
    setOtpFlag(false)
  }

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Check if input is numeric and not more than 5 digits
    if (inputValue.length <= 6) {
      setValue(inputValue);
    }
  };

  const otpSubmitHandler = async (e) => {
    e.preventDefault();
    setSignErrorMsg(''); 

    try {
      setOtpLoading(true);
      const userEmail = localStorage.getItem("email");

      const userDataObj = {
        email: userEmail,
        userOtp: otpValue
      }

      const { data } = await axios.post(`${endPoint}/api/giveaway/otp/verify`, userDataObj);
      localStorage.setItem("user", JSON.stringify(data))
      setOtpLoading(false);
      navigate("/card-to-join");
    } catch (error) {
      setOtpLoading(false);
      if (error.response && error.response.data) {
        // Server-side error
        setSignErrorMsg(`${error.response.data.message}`);
      } else {
        // Client-side or network error
        setSignErrorMsg('An error occurred while signing up. Please try again later.');
      }
    }
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <Form onSubmit={otpSubmitHandler}>
              <h1 className="form-heading">Verify your Email</h1>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>One Time Password sent to your email.</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="OTP"
                  value={otpValue}
                  onChange={handleChange}
                />
                <Form.Text className="text-muted">
                  Do not share your otp.
                </Form.Text>
              </Form.Group>
              {signErrorMsg && <p style={{ color: "red" }}>{signErrorMsg}</p>}
              <Button
                className="login-submit-btn"
                variant="primary"
                type="submit"
              >
                Confirm Verification &nbsp;&nbsp;
                {otpLoading && <AutorenewOutlinedIcon className="rotate" fontSize="small" />}
              </Button>

              <div className="not-have-account">
              <p className="not-have-acc-txt">Back to Sign Up?</p>
              <p className="to-sign-btn" onClick={returntoSignForm} >
                {" "}
                Sign Up?
              </p>
            </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmailForm;
