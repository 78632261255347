import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <>
      <footer id="rydeon-footer">
        <div className="container">
        <div className="row">
            <div className="col-md-6">
            </div>
            {/* <div className="col-md-6">
              <p className="footer-links-heading">Other Links</p>
              <ul className="rydeon-footer-ul">
                <li>
                  <a href="#">
                    <FacebookIcon
                      fontSize="large"
                      className="social-links-icons"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <LinkedInIcon
                      fontSize="large"
                      className="social-links-icons"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <InstagramIcon
                      fontSize="large"
                      className="social-links-icons"
                    />
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
          <div style={{textAlign:"center"}}>
          <p className="footer-links-heading">Contact Info</p>
              <a className="contact-anchor mb-5" href="mailto:offers@giveawayshop.online">
                offers@giveawayshop.online
              </a>
          </div>
          <div className="copyright-footer">© 2024 Giveawayshop All Rights Reserved</div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
