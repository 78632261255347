import React from 'react'
import { useLocation } from 'react-router-dom';
import Banner from './Banner'
import Timer from './Intro'
import HowItWorks from './HowItWorks'
import Testimonial from './Testimonial'
import CardInput from './CardInput'
import SafetyAndSecurity from './SafetyAndSecurity'
import JoinRefferal from './JoinRefferal'
import WhyChooseRydeon from './WhyChooseRydeon'
import "./home.css"

const HomePage = () => {
  
  return (
    <>
      <Banner /> 
      <Timer />
      <HowItWorks />
      <Testimonial />
      {/* <SafetyAndSecurity /> */}
      {/* <JoinRefferal /> */}
      {/* <WhyChooseRydeon /> */}
      {/* <CardInput /> */}

    </>
  )
}

export default HomePage;