import React from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from "@mui/icons-material/People";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import GridOnIcon from "@mui/icons-material/GridOn";
import Confetti from "react-confetti";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";

const HowItWorks = () => {
  return (
    <>
          {/* <Confetti  className="home-confetti-comp"/> */}
      <div className="container how-works-section mt-5 mb-5" id="howItWorksSection">
        <p className="how-works-h">Giveaways</p>
        <div className="row for-rider">
          <p className="how-works-h-rider">Iphone 16 Pro</p>
          <div className="col-md-6">
            <div>
              <img
                className="rider-image"
                src="assets/download/iphone-1.jpg"
                alt="rider image"
              />
            </div>
          </div>
          <div className="col-md-6">
            <ul className="rider-works-steps-ul">
              <li>
                <b className="how-works-bold">
                  <AttachMoneyIcon className="how-wirks-icons" />
                  Earn Giveaway:
                </b>
                <p>
                 Win the brand-new iPhone 16!
                </p>
              </li>
              <li>
                <b className="how-works-bold">
                <hr />
                  <AccessTimeIcon className="how-wirks-icons" /> Limited Time:
                </b>
                <p>
                Limited time offer – Act fast and win!
                </p>
              </li>
              <li>
                <b className="how-works-bold">
                <hr />
                  <PeopleIcon className="how-wirks-icons" />Become Our Community :
                </b>
                <p>
                Secure your spot in seconds – Just sign up and add your card!
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row for-driver mt-4">
          {/* <p className="how-works-h-driver">For Driver</p> */}
          <div className="col-md-6">
            <ul className="driver-works-steps-ul">
              {/* <li>
                <b className="how-works-bold">
                  <MonetizationOnIcon className="how-wirks-icons" /> Earn More:{" "}
                </b>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto itaque eaque dolore.
                </p>
              </li> */}
              <li>
                <b className="how-works-bold">
                <hr />
                  <GridOnIcon className="how-wirks-icons" /> Transparency:{" "}
                </b>
                <p>
                No charges, just a quick verification for trust. This is your chance to be a part of something big
                </p>
              </li>
              <li>
                <b className="how-works-bold">
                <hr />
                  <HealthAndSafetyIcon className="how-wirks-icons" /> Safety
                  First:
                </b>
                <p>
                We prioritize your safety with thorough iPhone inspections and 24/7 support, ensuring your peace of mind.
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="">
              <img
                className="driver-image"
                src="https://imageio.forbes.com/specials-images/imageserve/66bcc543070b6a7e21f0fc74/0x0.jpg?format=jpg&height=600&width=1200&fit=bounds"
                alt="driver image"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
