import React, { useState } from "react";
import "./Testimonial.css";

const testimonials = [
  {
    name: "Emily",
    position: "Chef",
    text: "I entered a giveaway on a whim and ended up winning a brand-new tablet! I couldn’t believe how easy it was to participate, and the whole process felt completely fair. GiveawayShop is the real deal, and I’m definitely going to enter more!",
    image: "https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg",
  },
  {
    name: "David Smith",
    position: "Freelancer",
    text: "I’ve tried so many giveaway sites, but GiveawayShop is by far the best. The entries were straightforward, and the prize was delivered so fast. I won a gift card and used it right away—so excited!",
    image: "https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg",
  },
  {
    name: "Sarah Johnson",
    position: "Teacher",
    text: "The best part about GiveawayShop is how easy it is to join. I signed up for the iPhone 16 giveaway and the process was seamless! Their customer service was top-notch, and I’m already telling my friends to give it a try!",
    image: "https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg",
  },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <div className="pt-5 pb-5" style={{backgroundColor: "#fafafa"}}>
        <p className="how-works-h text-center what-people-say-h">What People are Saying</p>
        <div className="testimonial-container mt-5 mb-5">
          <div className="testimonial-card">
            <img
              src={testimonials[currentIndex].image}
              alt={testimonials[currentIndex].name}
              className="testimonial-image"
            />
            <p className="testimonial-text">
              "{testimonials[currentIndex].text}"
            </p>
            <h3 className="testimonial-name">
              {testimonials[currentIndex].name}
            </h3>
            <p className="testimonial-position">
              {testimonials[currentIndex].position}
            </p>
          </div>
          <div className="testimonial-buttons">
            <button onClick={handlePrev} className="testimonial-btn">
              ←
            </button>
            <button onClick={handleNext} className="testimonial-btn">
              →
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
