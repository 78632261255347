import React, { useEffect, useState } from "react";
import { paySchema } from "../../schemas";
import { useFormik } from "formik";
import WarningIcon from '@mui/icons-material/Warning';
import axios from "axios";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import { endPoint } from "../../utils/api";
import CardTop from "../pages/home/CardTop";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField as Input,
} from "@mui/material";
import CardTopPart from "./CardTopPart";
import { formatCardNumber, formatExpiryDate } from "../../utils/formatting";
import { CalendarToday, CreditCard } from "@mui/icons-material";
const PaymentForm = ({ cardInfoSubmitHandler, loadingFlag, confettiFlag, setOpen }) => {
  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      country: "",
      city: "",
      pnumber: "",
      state: "",
      zipCode: "",
      name: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    },
    validationSchema: paySchema,
    onSubmit: async (values) => {
      // Implement payment logic here
      cardInfoSubmitHandler(values);
      // setOpen(true);
    },
  });

  // useEffect(() => {
  //   if (confettiFlag) {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //   }
  // }, [confettiFlag]);

  return (
    <>
      <CardTop />
      <Box
      style={{marginTop:"0px"}}
        sx={{
          background: "var(--light-grey)",
          py: 5,
          px: { xs: 2, md: 7 },
          maxWidth: "32rem",
          margin: "0 auto",
          my: 5,
          borderRadius: "20px",
        }}
      >
        <Box>
          <Box>
            <form onSubmit={formik.handleSubmit}>
              <Typography
                variant="h5"
                className="fw-500 text-center"
                sx={{ pb: 3 }}
              >
                Basic Info
              </Typography>
              <p className="mb-4" style={{color:"red", textAlign:"left", fontSize:"13px"}}><WarningIcon /> Please ensure you provide valid and accurate information, as only genuine entries will be eligible to win the prize!</p>
              <Box sx={{ pb: 2 }}>
                <Grid container alignItems="center" spacing={2}>
                  {/* Label on the left */}
                  <Grid item xs={4}>
                    <InputLabel sx={{ color: "#49454F" }}>
                      First Name
                    </InputLabel>
                  </Grid>

                  {/* Input on the right */}
                  <Grid item xs={8}>
                    <Input
                      fullWidth
                      id="fname"
                      name="fname"
                      value={formik.values.fname}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      placeholder="e.g John"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ pb: 2 }}>
                <Grid container alignItems="center" spacing={2}>
                  {/* Label on the left */}
                  <Grid item xs={4}>
                    <InputLabel sx={{ color: "#49454F" }}>Last Name</InputLabel>
                  </Grid>

                  {/* Input on the right */}
                  <Grid item xs={8}>
                    <Input
                      fullWidth
                      id="lname"
                      name="lname"
                      value={formik.values.lname}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      placeholder="e.g Doe"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ pb: 2 }}>
                <Grid container alignItems="center" spacing={2}>
                  {/* Label on the left */}
                  <Grid item xs={4}>
                    <InputLabel sx={{ color: "#49454F" }}>Country</InputLabel>
                  </Grid>

                  {/* Input on the right */}
                  <Grid item xs={8}>
                    <Input
                      fullWidth
                      id="country"
                      name="country"
                      value={formik.values.country}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      placeholder="country"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ pb: 2 }}>
                <Grid container alignItems="center" spacing={2}>
                  {/* Label on the left */}
                  <Grid item xs={4}>
                    <InputLabel sx={{ color: "#49454F" }}>State</InputLabel>
                  </Grid>

                  {/* Input on the right */}
                  <Grid item xs={8}>
                    <Input
                      fullWidth
                      id="state"
                      name="state"
                      value={formik.values.state}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      placeholder="state"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ pb: 2 }}>
                <Grid container alignItems="center" spacing={2}>
                  {/* Label on the left */}
                  <Grid item xs={4}>
                    <InputLabel sx={{ color: "#49454F" }}>City</InputLabel>
                  </Grid>

                  {/* Input on the right */}
                  <Grid item xs={8}>
                    <Input
                      fullWidth
                      id="city"
                      name="city"
                      value={formik.values.city}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      placeholder="city"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ pb: 2 }}>
                <Grid container alignItems="center" spacing={2}>
                  {/* Label on the left */}
                  <Grid item xs={4}>
                    <InputLabel sx={{ color: "#49454F" }}>
                      Zip/Postal Code
                    </InputLabel>
                  </Grid>

                  {/* Input on the right */}
                  <Grid item xs={8}>
                    <Input
                      fullWidth
                      id="zipCode"
                      name="zipCode"
                      value={formik.values.zipCode}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      placeholder="e.g 123456"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ pb: 4 }}>
                <Grid container alignItems="center" spacing={2}>
                  {/* Label on the left */}
                  <Grid item xs={4}>
                    <InputLabel sx={{ color: "#49454F" }}>
                      Phone Number
                    </InputLabel>
                  </Grid>

                  {/* Input on the right */}
                  <Grid item xs={8}>
                    <Input
                      fullWidth
                      id="pnumber"
                      name="pnumber"
                      value={formik.values.pnumber}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      placeholder="+1234556789"
                    />
                  </Grid>
                </Grid>
              </Box>

              <hr className="mb-4" />

              <CardTopPart />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ pb: 2 }}>
                    <InputLabel sx={{ py: 0.5, color: "#49454F" }}>
                      Cardholder Name
                    </InputLabel>
                    <Input
                      fullWidth
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      placeholder="e.g John Doe"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ pb: 2 }}>
                    <InputLabel sx={{ py: 0.5, color: "#49454F" }}>
                      Card Number
                    </InputLabel>
                    <Input
                      fullWidth
                      id="cardNumber"
                      name="cardNumber"
                      value={formatCardNumber(formik.values.cardNumber)}
                      onChange={(e) => {
                        e.target.value = formatCardNumber(e.target.value);
                        formik.handleChange(e);
                      }}
                      error={
                        formik.touched.cardNumber &&
                        Boolean(formik.errors.cardNumber)
                      }
                      helperText={
                        formik.touched.cardNumber && formik.errors.cardNumber
                      }
                      sx={{
                        "& .MuiInputBase-root": {
                          paddingLeft: "8px",
                        },
                      }}
                      inputProps={{ maxLength: 19 }}
                      placeholder="1234 1234 1234 1234"
                      // placeholder="e.g John Doe"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" sx={{ p: 0 }}>
                              <CreditCard className="main-color" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ py: 0.5, color: "#49454F" }}>
                    Expiry Date
                  </InputLabel>
                  <Input
                    variant="outlined"
                    fullWidth
                    id="expiryDate"
                    name="expiryDate"
                    value={formik.values.expiryDate}
                    onChange={(e) => {
                      e.target.value = formatExpiryDate(e.target.value);
                      formik.handleChange(e);
                    }}
                    error={
                      formik.touched.expiryDate &&
                      Boolean(formik.errors.expiryDate)
                    }
                    helperText={
                      formik.touched.expiryDate && formik.errors.expiryDate
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarToday
                            sx={{ color: "#ABABAB", width: "1rem" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="MM/YY"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ py: 0.5, color: "#49454F" }}>
                    CVV
                  </InputLabel>
                  <Input
                    variant="outlined"
                    fullWidth
                    id="cvv"
                    name="cvv"
                    value={formik.values.cvv}
                    onChange={formik.handleChange}
                    error={formik.touched.cvv && Boolean(formik.errors.cvv)}
                    helperText={formik.touched.cvv && formik.errors.cvv}
                    inputProps={{ maxLength: 3 }}
                    placeholder="e.g 123"
                  />
                </Grid>
              </Grid>

              <Button
                style={{ backgroundColor: "#f68341" }}
                variant="contained"
                type="submit"
                sx={{
                  width: "100%",
                  mt: 4,
                  py: 1.3,
                  fontWeight: "500",
                }}
                className="normal-text"
              >
                Submit&nbsp;&nbsp;
                {loadingFlag && (
                  <AutorenewOutlinedIcon className="rotate" fontSize="small" />
                )}
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PaymentForm;
