import React from 'react'
import CardInput from '../home/CardInput'
import PaymentForm from '../../components/PaymentForm'
import AuthBanner from '../auth/AuthBanner'

const Card = () => {
  return (
    <>
        <AuthBanner />
        <CardInput />
    </>
  )
}

export default Card